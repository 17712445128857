import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'

import TextRenderer from './../TextRenderer'

import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'

const Item = ({ blok, className }) => {

    const wrapperRef = useRef(null)
    const imageRef = useRef(null)

    // let fixer = 0.004
    // let outbreak = 8

    // const mouseMoveHandler = e => {
        
    //     let clientX =  e.clientX - (wrapperRef.current.getBoundingClientRect().width * 1.5)
    //     let clientY =  e.clientY - (wrapperRef.current.getBoundingClientRect().height * 1.5)

    //     let item = imageRef.current
        
    //     gsap.to(item, {
    //         x: (item.offsetLeft + clientX * outbreak) * fixer,
    //         y: (item.offsetTop + clientY * outbreak) * fixer,
    //         duration: 3,
    //         ease: 'power2.easeOut'
    //     })

    // }

    // useEffect(() => {
    //     wrapperRef.current.addEventListener('mousemove', mouseMoveHandler)
        
    //     return () => {
    //         wrapperRef.current.removeEventListener('mousemove', mouseMoveHandler)
    //     }
    // }, [])

    return (
        <SbEditable content={blok} key={blok._uid}>
            <li className={className} ref={wrapperRef}>
                <div className={`row ${styles['vertical-block__inner']}`}>
                    <div className={`col col-12 col-md-6 ${styles['vertical-block__body']}`}>
                        <TextRenderer text={blok.body} />
                    </div>
                    <div className={styles['vertical-block__image-wrapper']}>
                        <ResponsiveImage ref={imageRef} className={styles['vertical-block__image']} image={blok.image} />
                    </div>
                </div>
            </li>
        </SbEditable>
    )
}

const VerticalBlocks = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['vertical-blocks']}>
                <div className='container container--small'>
                    <ul className={styles['vertical-blocks__list']}>
                        {blok.items && blok.items.map(entry =>
                            <Item key={entry._uid} className={styles['vertical-block']} blok={entry} />
                        )}
                    </ul>
                </div>
            </section>
        </SbEditable>
    )
}

export default VerticalBlocks
