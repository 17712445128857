import React, { useState, useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'
import cx from 'classnames'

import TextRenderer from '../TextRenderer'
import ResponsiveImage from '../ResponsiveImage'

import { locale } from './../../../static/data'
import { getCurrentLang } from '../../utils/helpers'

import styles from './styles.module.scss'

const Item = ({ blok, itemOpen, setItemOpen }) => {
    
    const wrapperRef = useRef(null)
    const contentRef = useRef(null)
    const innerContentRef = useRef(null)
    const readMoreRef = useRef(null)

    const wrapperClasses = cx({
        [styles['collective-item']]: true,
        [styles['collective-item--open']]: itemOpen
    })

    const innerClasses = cx({
        [styles['collective-item__content']]: true,
        [styles['collective-item__content--open']]: itemOpen
    })

    useEffect(() => {
        gsap.set(contentRef.current, { y: 20.0, height: 0.0, alpha: 0.0 })
    }, [])

    useEffect(() => {

        let duration = 0.5
        let easeIn = 'power3.easeIn'
        let easeOut = 'power3.easeOut'
        
        // gsap.to(wrapperRef.current, { padding: '7.5rem 0', duration: duration, ease: easeIn })
        
        if (itemOpen) {
            gsap.to(readMoreRef.current, { alpha: 0.0, duration: duration / 2, ease: easeOut })
            gsap.to(contentRef.current, { alpha: 1.0, y: 0.0, height: 'auto', duration: duration, ease: easeOut, delay: 0.25 })
            gsap.to(innerContentRef.current, { alpha: 1.0, y: 0.0, duration: duration, ease: easeOut, delay: 0.25 })
        } else {
            gsap.to(contentRef.current, { alpha: 0.0, height: 0.0, duration: duration, ease: easeIn })
            gsap.to(innerContentRef.current, { alpha: 0.0, y: 20.0, duration: duration, ease: easeIn })
            gsap.to(readMoreRef.current, { alpha: 1.0, duration: duration / 2, ease: easeIn, delay: 0.25 })
        }

    }, [itemOpen])

    const mouseToggle = direction => {
        // if (itemOpen) return
        // gsap.to(wrapperRef.current, { padding: direction == 'enter' ? '8rem 0' : '7.5rem 0', duration: 0.25 })
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <li ref={wrapperRef} className={wrapperClasses} onClick={() => setItemOpen(blok._uid)} onMouseEnter={() => mouseToggle('enter')} onMouseLeave={() => mouseToggle('leave')}>
                <figure className={styles['collective-item__image-wrapper']}>
                    {blok.media.filename && <ResponsiveImage className={styles['collective-item__image']} image={blok.media} />}
                </figure>
                <div className={styles['collective-item__inner']}>
                    <div className='container'>
                        <h4 className={`heading heading-2 ${styles['collective-item__title']}`}><b>{blok.title}</b></h4>
                        <button ref={readMoreRef} className={`button link link--white`} onClick={() => setItemOpen(blok._uid)}>Read more</button>
                        <div ref={contentRef} className={innerClasses}>
                            <div ref={innerContentRef}>
                                <TextRenderer text={blok.body} />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </SbEditable>
    )
}

const Collective = ({ blok }) => {
    
    const [activeItem, setActiveItem] = useState(-1)

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section>
                <ul className={styles['collective__list']}>
                    {blok.items && blok.items.map(entry => 
                        <Item 
                            itemOpen={activeItem == entry._uid ? true : false}
                            setItemOpen={(e) => e == activeItem ? setActiveItem(-1) : setActiveItem(e)}
                            key={entry._uid} 
                            blok={entry} 
                        />
                    )}
                </ul>
            </section>
        </SbEditable>
    )
}

export default Collective
