import React from 'react'
import SbEditable from 'storyblok-react'
import ResponsiveImage from '../ResponsiveImage'
import TextRenderer from '../TextRenderer'

import styles from './styles.module.scss'

const Item = ({ blok, className }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <li className={className}>
                <a className={`link ${styles['grid-item__link']}`} href={blok.link.cached_url} target='_blank'>
                    <figure className={styles['grid-item__image-wrapper']}>
                        <ResponsiveImage className={styles['grid-item__image']} image={blok.image} mobile={200} desktop={600} large={800} />
                    </figure>
                    <h5 className={`heading heading-4 ${styles['grid-item__title']}`}><b>{blok.title}</b></h5>
                    <TextRenderer text={blok.description} className={styles['grid-item__description']} />
                </a>
            </li>
        </SbEditable>
    )
}

const Grid = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['grid']}>
                <div className='container'>
                    <ul className={styles['grid__list']}>
                        {blok.items && blok.items.map(entry => 
                            <Item className={styles['grid-item']} key={entry._uid} blok={entry} />
                        )}
                    </ul>
                </div>
            </section>
        </SbEditable>
    )
}

export default Grid