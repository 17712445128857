import React, { useEffect, useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import stickybits from 'stickybits'

import TextRenderer from './../TextRenderer'
import ImageTitle from './../ImageTitle'

import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'

const VerticalSlides = ({ blok }) => {
    
    const imageWrapperRefs = useRef([])
    const containerRef = useRef(null)
    const imageRefs = useRef([])
    const contentRefs = useRef([])
    const titleRefs = useRef([])
    
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
    
        if (typeof window !== 'undefined') {
            gsap.registerPlugin(ScrollTrigger)
        }

        stickybits(containerRef.current)

        let tlArr = []

        imageWrapperRefs.current.forEach((image, i) => {

            if (i != 0) {
                gsap.set(image, { alpha: 0.0 })
            }

            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: contentRefs.current[i],
                    start: '45%',
                    end: 'bottom bottom',
                    toggleActions: 'play none none reverse'
                },
                onStart: () => setCurrentIndex(i + 1),
                onReverseComplete: () => setCurrentIndex(i),
                paused: true
            })

            if (imageWrapperRefs.current[i + 1]) {
                tl.to(imageWrapperRefs.current[i + 1], { 
                    alpha: 1.0,
                    duration: 0.5,
                    ease: 'expo.easeOut'
                }, 0.0)
            }

            tlArr.push(tl)
    
        })

        return () => {
            tlArr.forEach(item => {
                item.scrollTrigger.kill()
                item.kill()
            })
        }
        
    }, [])

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={`sticky ${styles['vertical-slides']}`}>
                <div ref={containerRef} className={styles['vertical-slides__images-container']}>
                    <div className={styles['vertical-slides__images-inner']}>
                        {blok.slides && blok.slides.map((entry, i) => (
                            <div ref={el => imageWrapperRefs.current[i] = el} id={`image-${i}`} className={`${styles['vertical-slides__image-wrapper']}`} key={i}>
                                {entry.media && (
                                    <>
                                        <ResponsiveImage ref={el => imageRefs.current[i] = el} className={styles['vertical-slides__image']} image={entry.media} mobile={100} desktop={800} large={1000} />
                                        <ImageTitle ref={el => titleRefs.current[i] = el} text={entry.media.alt} direction='vertical'/>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles['vertical-slides__content-container']}>
                    {blok.slides && blok.slides.map((entry, i) => (
                        <SbEditable content={entry} key={entry._uid}>
                            <div className={styles['vertical-slides__content-inner']} ref={el => contentRefs.current[i] = el}>
                                <div className={styles['vertical-slides__content-wrapper']}>
                                    <ResponsiveImage className={`${styles['vertical-slides__image']} ${styles['vertical-slides__image--mobile']}`} image={entry.media} mobile={400} desktop={100} large={100} />
                                    {entry.body && (
                                        <div className={styles['vertical-slides__content']}>
                                            <TextRenderer text={entry.body} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </SbEditable>
                    ))}
                </div>
                <div className={styles['vertical-slides__indicator']}>
                    <div className={styles['vertical-slides__indicator-inner']}>
                        <span className={`link ${styles['vertical-slides__indicator-label']}`}><span>{currentIndex + 1}</span> / {blok.slides.length}</span>
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default VerticalSlides
