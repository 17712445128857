import React, { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'

import 'mapbox-gl/dist/mapbox-gl.css'

import styles from './styles.module.scss'

mapboxgl.accessToken ='pk.eyJ1IjoidGlqc2x1aXRzZSIsImEiOiJja2dvczQ0YjkwM2F6MnF0ZDF5ZDc5cmgzIn0.lUty_APItLA9uDIljelmfQ'

const CustomMap = ({ title }) => {

	const mapNode = useRef(null)
	const mapRef = useRef(null)

    useEffect(() => {

		const map = new mapboxgl.Map({
			container: mapNode.current,
			style: 'mapbox://styles/tijsluitse/ckifzcbgf0wkj19o2bwvlvis7',
			center: [4.669640, 52.078580],
			zoom: 13,
			attributionControl: false
		})
	
		mapRef.current = map
		window.map = map

		map.scrollZoom.disable()
    
        map.on('load', () => {

            let popup = new mapboxgl.Popup({
                closeButton: false,
                anchor: 'bottom',
                closeOnClick: false
            }).setHTML(`<h1 class='link link--white'>${title}</h1>`)

            let marker = new mapboxgl.Marker({ 
                anchor: 'bottom' 
            }).setLngLat([4.669640, 52.078580]).setPopup(popup).addTo(map)

            marker.togglePopup()

        })

		return () => {
			map.remove()
		}
	}, [])

    return (
		<div ref={mapNode} className={styles['map']}></div>
    )
}

export default CustomMap