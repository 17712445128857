import React, { useState, useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { SwiperSlide } from 'swiper/react'
import gsap from 'gsap'
import cx from 'classnames'

import TextRenderer from '../TextRenderer'
import DynamicCarousel from './../DynamicCarousel'
import DynamicIcon from './../DynamicIcon'
import ResponsiveImage from '../ResponsiveImage'

import styles from './styles.module.scss'

const Popup = React.forwardRef((props, ref) => {
    
    const popupClasses = cx({
        [styles['evolution-popup']]: true,
        [styles['evolution-popup--active']]: props.showPopup,
        [styles['evolution-popup--more-space']]: props.blok && props.blok.more_space
    })
    
    return (
        <div ref={ref} className={popupClasses}>
            <div className={styles['evolution-popup__inner']} onClick={props.closePopup}>
                <div className={styles['evolution-popup__header']}>
                    <h4 className='heading-3'><b>What I will become</b></h4>
                    {props.blok && <p className='paragraph paragraph--small'><span className='light'>Once fully grown my pot size is {props.blok.title}.</span></p>}
                </div>
                <div className={props.slideClasses}>
                    {props.blok && <SlideInner {...props} />}
                </div>
                <DynamicIcon type='cross' className={`button ${styles['evolution-popup__close-icon']}`} />
            </div>
        </div>
    )
})

const SlideInner = ({ blok, small, imageWrapperClasses, onClick }) => {

    const [timeline, setTimeline] = useState(null)

    const itemRef = useRef(null)
    const imageRef = useRef(null)
    const shadeRef = useRef(null)

    useEffect(() => {

        if (typeof window !== 'undefined' && window.innerWidth < 768) return
        
        let duration = 2
        let tl = gsap.timeline({ yoyo: true, repeat: -1, paused: true })

        tl.to(imageRef.current, { y: small ? -5 : -10, duration: duration, ease: 'none' }, 0)
        tl.to(shadeRef.current, { scaleX: 0.5, scaleY: 0.5, duration: duration, ease: 'none' }, 0)

        setTimeline(tl)

        return () => {
            timeline && timeline.kill()
        }

    }, [])

    const toggleMouseMove = state => {
        
        if (typeof window !== 'undefined' && window.innerWidth < 768) return

        if (timeline) state == 'enter' ? timeline.play() : timeline.pause()
        
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <article ref={itemRef} className={styles['evolution-slide__inner']} onMouseEnter={() => toggleMouseMove('enter')} onMouseLeave={() => toggleMouseMove('leave')} onClick={onClick}>
                <figure className={imageWrapperClasses}>
                    {blok.image.filename && <ResponsiveImage ref={imageRef} className={styles['evolution-slide__image']} image={blok.image} mobile={200} desktop={400} large={400} />}
                </figure>
                <div ref={shadeRef} className={styles['evolution-slide__image-shadow']} style={{ width: `${blok.shadow_width}rem`, marginLeft: `-${blok.shadow_width / 2}rem` }} />
                <header className={styles['evolution-slide__title-wrapper']}>
                    <h4 className={`link link--medium ${styles['evolution-slide__title']}`}><span className='bold'>{blok.title}</span>{blok.subtitle}</h4>
                </header>
            </article>
        </SbEditable>
    )
}

const Evolution = ({ blok }) => {

    const popupRef = useRef(null)

    const [moving, setMoving] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [dataPopup, setDataPopup] = useState(null)
    const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth < 768)

    let slidesPerView = blok.slides.length == 4 ? 5 : 4.5
    let slidesOffsetBefore = 0
    let slidesOffsetAfter = 0
    let touchRatio = 1

    if (typeof window !== 'undefined' && window.innerWidth > 768) {
        if (blok.slides.length == 4) {
            slidesOffsetBefore = 0
            slidesOffsetAfter = 0
            touchRatio = 0
        } else {
            slidesOffsetBefore = 150
            slidesOffsetAfter = 150
        }
    }

    const params = {
        slidesPerView: isMobile ? 1.25 : slidesPerView,
        spaceBetween: 40,
        centeredSlides: isMobile ? true : false,
        grabCursor: true,
        slidesOffsetBefore: slidesOffsetBefore,
        slidesOffsetAfter: slidesOffsetAfter,
        touchRatio: touchRatio,
        scrollbar: {
            draggable: true,
            snapOnRelease: true
        }
    }

    const headingClasses = cx({
        [styles['evolution__heading']]: true,
        [styles['evolution__heading--more-space']]: blok.containers_size == 'small',
    })

    const slideClasses = cx({
        [styles['evolution-slide']]: true,
        [styles['evolution-slide--moving']]: moving,
        [styles['evolution-slide--small']]: blok.containers_size == 'small',
        [styles['evolution-slide--inverted']]: blok.background_color == '#FFFFFF'
    })

    const imageWrapperClasses = cx({
        [styles['evolution-slide__image-wrapper']]: true,
        [styles['evolution-slide__image-wrapper--large']]: blok.images_size == 'large',
    })

    useEffect(() => {
        gsap.set(popupRef.current, { scale: 1.05 })
    })

    const openPopup = data => {
        setDataPopup(data)
        gsap.to(popupRef.current, { alpha: 1.0, scale: 1.0, duration: 0.25, ease: 'power2.easeOut' })
        setShowPopup(true)
    }

    const closePopup = () => {
        gsap.to(popupRef.current, { alpha: 0.0, scale: 1.05, duration: 0.25, ease: 'power2.easeOut' })
        setShowPopup(false)
    }
    
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['evolution']} style={{ backgroundColor: blok.background_color }}>
                <div className={headingClasses}>
                    <TextRenderer text={blok.heading} />
                </div>
                <DynamicCarousel 
                    className={`carousel--evolution ${!isMobile && blok.slides.length == 4 && 'carousel--center'}`}
                    params={params} 
                    onTouchStart={() => setMoving(true)}
                    onTouchEnd={() => setMoving(false)}
                >
                    {blok.slides && blok.slides.map((entry, i) => 
                        <SwiperSlide key={entry._uid} virtualIndex={i} className={slideClasses}>
                            <SlideInner onClick={() => entry.popup[0] && openPopup(entry.popup[0])} moving={moving} blok={entry} small={blok.containers_size == 'small'} imageWrapperClasses={imageWrapperClasses} />
                        </SwiperSlide>
                    )}
                </DynamicCarousel>
                <Popup ref={popupRef} blok={dataPopup} showPopup={showPopup} small={blok.containers_size == 'small'} imageWrapperClasses={imageWrapperClasses} slideClasses={slideClasses} closePopup={() => closePopup()} />
            </section>
        </SbEditable>
    )
}

export default Evolution
