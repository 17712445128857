import React from 'react'
import SbEditable from 'storyblok-react'
import cx from 'classnames'

import TextRenderer from '../TextRenderer'

import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'

const Information = ({ blok }) => {

    const listClasses = cx({
        [styles['information__list']]: true,
        [styles['information__list--small']]: blok.size == 'small'
    })

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['information']} style={{ backgroundColor: blok.background_color }}>
                <div className={styles['information__header']}>
                    <TextRenderer text={blok.heading} />
                </div>
                <div className='container container--small'>
                    <div className={listClasses}>
                        {blok.columns && blok.columns.map(entry => {
                            return (
                                <SbEditable content={entry} key={entry._uid}>
                                    <div className={styles['information-column']}>
                                        <div className={styles['information-column__inner']}>
                                            {entry.image.filename && <ResponsiveImage className={styles['information-column__image']} image={entry.image} />}
                                            <div>
                                                <TextRenderer text={entry.body} linkAsText className={styles['information-column__text']} />
                                            </div>
                                        </div>
                                    </div>
                                </SbEditable>
                            )
                        })}
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default Information
