import React, { useEffect, useRef, useState } from "react"
import SbEditable from "storyblok-react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import cx from "classnames"

import ImageTitle from "./../ImageTitle"
import ResponsiveImage from "./../ResponsiveImage"

import styles from "./styles.module.scss"
import LanguageSwitch from "../LanguageSwitch"

const HeroBanner = ({ blok }) => {
  const wrapperRef = useRef(null)
  const imageRef = useRef(null)
  const titleRef = useRef(null)

  const [imageSrc, setImgSrc] = useState(null)

  useEffect(() => {
    let offset = 5

    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.set(imageRef.current, { yPercent: -offset, scale: 1.1 })

    const tl = gsap.timeline({
      immediateRender: false,
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "center bottom",
        end: "center top +=100%",
        scrub: true,
        markers: false,
      },
    })

    tl.to(imageRef.current, {
      yPercent: offset,
      ease: "none",
    })

    return () => {
      if (tl) tl.kill()
    }
  }, [])

  const resizeHandler = () => {
    let vh = Math.max(wrapperRef.current.clientHeight, window.innerHeight || 0)
    wrapperRef.current.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    resizeHandler()

    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  useEffect(() => {
    if (blok.show_random_images && blok.random_images) {
      const randomIndex = Math.floor(Math.random() * blok.random_images.length)
      const randomImage = blok.random_images[randomIndex]
      setImgSrc(randomImage)
    } else {
      setImgSrc(blok.media)
    }
  }, [])

  const bannerClasses = cx({
    [styles["hero-banner"]]: true,
    [styles["hero-banner--in-container"]]: blok.type == "in-container",
    [styles["hero-banner--no-spacing"]]: blok.no_spacing,
    [styles["hero-banner--first-component"]]: blok.first_component,
  })

  return (
    <SbEditable content={blok} key={blok._uid}>
      <section ref={wrapperRef} className={bannerClasses}>
        <div className={`${styles["hero-banner__inner"]}`}>
          {imageSrc && (
            <div className={styles["hero-banner__image-wrapper"]}>
              <ResponsiveImage
                ref={imageRef}
                className={styles["hero-banner__image"]}
                image={imageSrc}
                noLazyLoad
              />
            </div>
          )}
          {!blok.show_random_images && (
            <ImageTitle
              ref={titleRef}
              className={styles["hero-banner__image-title"]}
              direction="horizontal"
              text={blok.title}
            />
          )}
          {blok.show_language_selector && (
            <LanguageSwitch
              className={styles["hero-banner__language-switch"]}
            />
          )}
        </div>
      </section>
    </SbEditable>
  )
}

export default HeroBanner
