import React from 'react'
import SbEditable from 'storyblok-react'

const AnchorSection = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div style={{ width: '100%', position: 'relative' }} name='anchor_section' id={blok.id}></div>
        </SbEditable>
    )
}

export default AnchorSection
