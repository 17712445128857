import React from 'react'
import SbEditable from 'storyblok-react'

import ImageTitle from '../ImageTitle'
import ResponsiveImage from '../ResponsiveImage'
import TextRenderer from '../TextRenderer'

import styles from './styles.module.scss'

const Gallery = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['gallery']}>
                <div className={`row ${styles['gallery__row']}`}>
                    <div className={`col col-12 col-md-9 ${styles['gallery__col']}`}>
                        <figure className={styles['gallery__image-wrapper']}>
                            <ResponsiveImage className={styles['gallery__image']} image={blok.media[0]} mobile={400} desktop={1280} large={1440} />
                            <ImageTitle text={blok.media[0].alt} direction='vertical'/>
                        </figure>
                    </div>
                </div>
                <div className={`row ${styles['gallery__row']}`}>
                    <div className={`col col-12 col-md-4 offset-md-1 ${styles['gallery__col']}`}>
                        <figure className={styles['gallery__image-wrapper']}>
                            <ResponsiveImage className={`${styles['gallery__image']} ${styles['gallery__image--padding-top']}`} image={blok.media[1]} mobile={400} desktop={1280} large={1440} />
                            <ImageTitle text={blok.media[1].alt} direction='vertical'/>
                        </figure>
                    </div>
                    <div className={`col col-12 col-md-6 offset-md-1 ${styles['gallery__col']}`}>
                        <figure className={styles['gallery__image-wrapper']}>
                            <ResponsiveImage className={styles['gallery__image']} image={blok.media[2]} mobile={400} desktop={1280} large={1440} />
                            <ImageTitle text={blok.media[2].alt} direction='vertical'/>
                        </figure>
                        <div className={styles['gallery__body']}>
                            <TextRenderer text={blok.body} />
                        </div>
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default Gallery