import React from 'react'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql } from 'gatsby'

import DynamicComponent from './DynamicComponent'
import Footer from './Footer'

const DynamicPage = ({ blok, settings }) => {

	const contentBloks = blok.body && blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)
	const footerBlok = blok.footer && blok.footer.map(childBlok => <Footer blok={childBlok} key={childBlok._uid} settings={settings} />)
	
	return (
		<SbEditable content={blok} key={blok._uid}>
			{contentBloks}
			{footerBlok}
		</SbEditable>
	)
}

export default DynamicPage
