import React from 'react'
import SbEditable from 'storyblok-react'
import TextRenderer from '../TextRenderer'

import Map from './../Map'

import styles from './styles.module.scss'

const Contact = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['contact']}>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className={`col col-12 col-md-7 ${styles['contact__map']}`}>
                            <Map title={blok.pin_label} />
                        </div>
                        <div className={`col col-12 col-md-4 offset-md-1 ${styles['contact__body-col']}`}>
                            <TextRenderer text={blok.body} className={styles['contact__body']} linkAsText />
                        </div>
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default Contact
