import React, { useRef } from 'react'
import SbEditable from 'storyblok-react'
import { SwiperSlide } from 'swiper/react'

import DynamicCarousel from './../DynamicCarousel'
import ResponsiveImage from './../ResponsiveImage'
import TextRenderer from './../TextRenderer'
import Link from './../Link'

import styles from './styles.module.scss'

const Carousel = ({ blok }) => {
    
    const sectionRef = useRef(null)
    const imageRefs = useRef([])

    const params = {
        slidesPerView: 1,
        speed: 2500,
        preloadImages: false,
        autoplay: {
            delay: 5000
        },
        scrollbar: {
            draggable: true
        }
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section ref={sectionRef} className={styles['carousel']}>
                {/* <Link to={blok.link && blok.link.cached_url} className={styles['carousel__link']}> */}
                    <DynamicCarousel className='carousel--slideshow' params={params}>
                        {blok.slides.map((entry, i) => {
                            return (
                                <SwiperSlide key={i} virtualIndex={i} className={styles['carousel-slide']}>
                                    <div className={styles['carousel-slide__inner']}>
                                        <figure className={styles['carousel-slide__image-wrapper']}>
                                            <ResponsiveImage ref={el => imageRefs.current[i] = el} className={styles['carousel-slide__image']} image={entry.media} />
                                        </figure>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </DynamicCarousel>
                    <div className={styles['carousel__body']}>
                        <TextRenderer text={blok.body} />
                    </div>
                {/* </Link> */}
            </section>
        </SbEditable>
    )
}

export default Carousel
