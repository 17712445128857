import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import cx from 'classnames'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import ImageTitle from './../ImageTitle'
import ResponsiveImage from './../ResponsiveImage'

import styles from './styles.module.scss'

const Banner = ({ blok }) => {

    const bannerRef = useRef(null)
    const imageRef = useRef(null)
    
    useEffect(() => {

        let offset = 5
        
        if (typeof window !== 'undefined') {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.set(imageRef.current, { yPercent: -offset, scale: 1.25 })
        
        const tl = gsap.timeline({
            immediateRender: false,
            scrollTrigger: {
                trigger: bannerRef.current,
                start: 'center bottom',
                end: 'center top +=100%',
                scrub: true,
                markers: false
            }
        })

        tl.to(imageRef.current, {
            yPercent: offset,
            ease: 'none'
        })

        return () => {
            if (tl) tl.kill()
        }

    }, [])

    const bannerClasses = cx({
        [styles['banner']]: true,
        [styles['banner--in-container']]: blok.size == 'in-container',
    })

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section id='banner' ref={bannerRef} className={bannerClasses}>
                {blok.slides && blok.slides.length == 1 && blok.slides.map(entry => {
                    return (
                        <div className={`img-hover ${styles['banner__slide']}`} key={entry._uid}>
                            <ResponsiveImage ref={imageRef} className={styles['banner__image']} image={entry.media} desktop={2500} large={2500} />
                            <ImageTitle className={styles['banner__image-title']} direction='horizontal' text={entry.title || entry.media.alt} />
                        </div>
                    )
                })}
                {blok.slides && blok.slides.length > 1 && <p>build carousel</p>}
            </section>
        </SbEditable>
    )
}

export default Banner
