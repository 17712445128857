import React from 'react'
import SbEditable from 'storyblok-react'
import cx from 'classnames'
import { SwiperSlide } from 'swiper/react'

import TextRenderer from './../TextRenderer'
import DynamicCarousel from './../DynamicCarousel'
import ResponsiveImage from './../ResponsiveImage'

import styles from './styles.module.scss'

const Item = ({ blok, className }) => {

    const wrapperClasses = cx({
        'carousel--downloads': true,
        [styles['downloads-item__image-wrapper']]: true,
        [styles['downloads-item__image-wrapper--large']]: blok.image.length > 1
    })
    
    const imageClasses = cx({
        [styles['downloads-item__image']]: true,
        [styles['downloads-item__image--shadow']]: blok.add_shadow,
    })

    const params = {
        slidesPerView: 1,
        autoplay: {
            delay: 2500
        },
        scrollbar: {
            draggable: true
        }
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <li className={className}>
                {blok.image && blok.image.length == 1 && blok.image.map((entry, i) => 
                    <figure key={i} className={styles['downloads-item__image-wrapper']}>
                        <ResponsiveImage className={imageClasses} image={entry} mobile={200} desktop={400} large={600} />
                    </figure>
                )}
                {blok.image && blok.image.length > 1 && (
                    <DynamicCarousel params={params} className={wrapperClasses}>
                        {blok.image.map((entry, i) => 
                            <SwiperSlide key={i} virtualIndex={i}>
                                <ResponsiveImage className={imageClasses} image={entry} mobile={200} desktop={400} large={600} />
                            </SwiperSlide>
                        )}
                    </DynamicCarousel>
                )}
                <div className={styles['downloads-item__body']}>
                    <TextRenderer text={blok.body} className={`link link--large ${styles['downloads-item__description']}`} />
                </div>
            </li>
        </SbEditable>
    )
}

const Downloads = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['downloads']}>
                <div className='container'>
                    <ul className={styles['downloads__list']}>
                        {blok.items && blok.items.map(entry => 
                            <Item className={styles['downloads-item']} key={entry._uid} blok={entry} />
                        )}
                    </ul>
                </div>
            </section>
        </SbEditable>
    )
}

export default Downloads
