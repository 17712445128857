import React, { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { SwiperSlide } from 'swiper/react'

import ImageTitle from './../ImageTitle'
import DynamicCarousel from './../DynamicCarousel'
import ResponsiveImage from '../ResponsiveImage'

import styles from './styles.module.scss'

const SlideInner = ({ blok }) => {

    const resize = (image, params) => {
        
        let imageService = 'https://img2.storyblok.com/'
        let path = image.replace('https://a.storyblok.com', '')
        let newFile = imageService + params + '/filters:format(webp)' + path
        
        return newFile
    }

    return (
        <article className={styles['marquee-slide__inner']}>
            <a href={blok.link ? blok.link.cached_url : 'https://livingcreations.com/collection'} target='_blank' className={styles['marquee-slide__link']}>
                <ImageTitle className={styles['marquee-slide__image-title']} direction='vertical' text={blok.media.alt} />
                <ResponsiveImage className={styles['marquee-slide__image']} image={blok.media} mobile={300} desktop={300} large={600} noLazyLoad />
                {blok.award && <img className={styles['marquee-slide__award']} src={resize(blok.award, '100x0')} alt='KVBC Award' />}
                {/* <div className={styles['marquee-slide__title']}>
                    <h4 className='heading-4'>{blok.media.alt.replace('Hydrangea pan. ', '')}</h4>
                </div> */}
            </a>
        </article>
    )
}

const Marquee = ({ blok }) => {

    const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' && window.innerWidth < 768)

    const params = {
        loop: true,
        slidesPerView: isMobile ? 1.75 : 3.5,
        scrollbar: false,
        speed: 8000,
        grabCursor: false,
        scrollbar: false,
        autoplay: {
            delay: 1,
            disableOnInteraction: false
        }
    }

    return (
        <SbEditable content={blok}>
            <section className={styles['marquee-slides']} style={{ backgroundColor: blok.background_color }}>
                <DynamicCarousel className='carousel--marquee' params={params}>
                    {blok.slides && blok.slides.map((entry, i) => {
                        return (
                            <SwiperSlide key={entry._uid} virtualIndex={i} className={`img-hover ${styles['marquee-slide']}`}>
                                <SlideInner blok={entry} />
                            </SwiperSlide>
                        )
                    })}
                </DynamicCarousel>
            </section>
        </SbEditable>
    )
}

export default Marquee
