import React, { useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import Select from 'react-select'

import styles from './styles.module.scss'

const Location = ({ blok, className }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <li className={className}>
                <a className={styles['location__link']} href={blok.link && blok.link.url ? blok.link.url : '/'} target='_blank'>
                    <figure className={styles['location__image-wrapper']}>
                        {blok.logo.filename && <img className={styles['location__image']} src={blok.logo.filename} alt={blok.logo.alt} />}
                    </figure>
                </a>
            </li>
        </SbEditable>
    )
}

const WhereToBuy = ({ blok }) => {

    const [options, setOptions] = useState([])
    const [filteredItems, setFilteredItems] = useState(blok.items)

    const pushToArray = (arr, obj) => {
        const index = arr.findIndex((e) => e.value === obj.value)
    
        if (index === -1) {
            arr.push(obj)
        } else {
            arr[index] = obj
        }
    }

    const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    useEffect(() => {
        
        let optionsArr = []

        blok.items && blok.items.forEach(entry => {
            pushToArray(optionsArr, {
                value: entry.city,
                label: capitalizeFirstLetter(entry.city)
            })
        })

        setOptions(optionsArr)

    }, [])

    const filterItems = e => {

        if (!e) {
            setFilteredItems(blok.items)
            return
        }
    
        let items = blok.items.filter(entry => {
            return entry.city == e.value
        })

        setFilteredItems(items)

    }

    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            fontFamily: 'Circular Book',
            fontSize: '1.2rem'
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Circular Book',
            fontSize: '1.2rem',
            padding: '1.5rem',
            backgroundColor: state.isFocused ? '#eaeaea' : 'transparent',
            color: state.isFocused ? 'var(--color-dark)' : 'var(--color-grey-cold)',
            '&:active': {
                backgroundColor: 'transparent',
                color: 'var(--color-dark)'
            }
        }),
        input: (provided) => ({
            ...provided,
            fontFamily: 'Circular Book',
            fontSize: '1.2rem'
        }),
        singleValue: (provided) => ({
            ...provided,
            fontFamily: 'Circular Book',
            fontSize: '1.2rem',
            lineHeight: '1.5rem'
        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused ? 'var(--color-dark)' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--color-dark)' : provided.borderColor,
            }
        }),
        menuList: () => ({
            padding: 0
        }),
        noOptionsMessage: () => ({
            textAlign: 'left',
            fontFamily: 'Circular Book',
            fontSize: '1.2rem',
            padding: '1.5rem'
        })
    }  

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['where-to-buy']}>
                <div className='container'>
                    <div className={styles['where-to-buy__header']}>
                        {options && options.length > 0 && (
                            <Select 
                                className={styles['where-to-buy__filter']}
                                styles={customStyles}
                                options={options}
                                placeholder={blok.search_placeholder}
                                onChange={e => filterItems(e)}
                                isClearable={true}
                            />
                        )}
                        <div className={styles['where-to-buy__header-title']}>
                            <p className='link link--medium'>{`${filteredItems.length ? filteredItems.length : blok.items.length} ${blok.results_label}`}</p>
                        </div>
                    </div>
                    <ul className={styles['where-to-buy__list']}>
                        {filteredItems && filteredItems.map(entry => 
                            <Location className={styles['location']} key={entry._uid} blok={entry} />
                        )}
                    </ul>
                </div>
            </section>
        </SbEditable>
    )
}

export default WhereToBuy
