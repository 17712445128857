import React, { useEffect, useRef, useState, useImperativeHandle } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'

import TextRenderer from './../TextRenderer'
import HorizontalScroll from './../HorizontalScroll'
import ResponsiveImage from './../ResponsiveImage'

import styles from './styles.module.scss'

const Bumper = ({ text }) => {
    return (
        <div className={styles['team__bumper']}>
            <TextRenderer text={text} />
        </div>
    )
}

const Member = React.forwardRef(({ blok }, ref) => {

    const imageWrapperRef = useRef(null)
    const imageRef = useRef(null)
    const bodyRef = useRef(null)

    const [timeline, setTimeline] = useState(null)

    useEffect(() => {

        const tl = new gsap.timeline({ paused: true })

        tl.to(imageWrapperRef.current, { width: '120%', duration: 0.25, ease: 'expo.easeIn' }, 0.0)
        bodyRef.current && tl.to(bodyRef.current, { height: 'auto', duration: 0.25, ease: 'expo.easeIn' }, 0.15)

        setTimeline(tl)

        return () => {
            timeline && timeline.kill()
        }
    }, [])

    const toggleAnimation = direction => {

        if (typeof window !== 'undefined' && window.innerWidth < 768) return

        if (direction == 'in') {
            timeline && timeline.eventCallback('onStart', () => {
                imageWrapperRef.current.style.zIndex = '3'
            }).play()
        } else {
            timeline && timeline.eventCallback('onReverseStart', () => {
                imageWrapperRef.current.style.zIndex = '0'
            }).reverse()
        }

    }

    return (
        <article className={styles['team-member']} onMouseEnter={() => toggleAnimation('in')} onMouseLeave={() => toggleAnimation('out')}>
            <div className={styles['team-member__header']}>
                <h4 className='heading-4'>{blok.name}</h4>
                <p className={`link ${styles['team-member__title']}`}>{blok.title}</p>
                {blok.body && (
                    <div ref={bodyRef} className={styles['team-member__body']}>
                        <TextRenderer text={blok.body} />
                    </div>
                )}
            </div>
            <figure ref={imageWrapperRef} className={styles['team-member__image-wrapper']}>
                <ResponsiveImage ref={imageRef} className={styles['team-member__image']} image={blok.image} mobile={200} desktop={600} large={800} />
            </figure>
        </article>
    )
})

const Team = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={styles['team']}>
                <HorizontalScroll>
                    <div className={styles['team__horizontal-scroll']}>
                        <Bumper text={blok.heading} />
                        {blok.items && blok.items.map((entry, i) => 
                            <Member key={entry._uid} ref={el => memberRefs.current[i] = el} blok={entry}/>
                        )}
                        {!blok.hide_ending && <Bumper text={blok.ending} />}
                    </div>
                </HorizontalScroll>
                <div className={styles['team__mobile']}>
                    <Bumper text={blok.heading} />
                    <ul className={styles['team__mobile-list']}>
                        {blok.items && blok.items.map((entry, i) => 
                            <Member key={entry._uid} ref={el => memberRefs.current[i] = el} blok={entry}/>
                        )}
                    </ul>
                    {!blok.hide_ending && <Bumper text={blok.ending} />}
                </div>
            </section>
        </SbEditable>
    )
}

export default Team
