import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SbEditable from "storyblok-react"

import Thumbnail from "../Thumbnail"

import styles from "./styles.module.scss"

const Blog = blok => {
  const { entries } = useStaticQuery(graphql`
    query {
      entries: allStoryblokEntry(
        filter: { field_component: { in: ["blogpost"] } }
        sort: { fields: published_at, order: DESC }
      ) {
        nodes {
          id
          slug
          full_slug
          field_component
          name
          content
          published_at(formatString: "MMMM DD, YYYY", locale: "EN")
        }
      }
    }
  `)

  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className={styles["blog"]}>
        <div className="container container--small">
          <div className={styles["blog__list"]}>
            {entries.nodes.map(entry => (
              <Thumbnail key={entry.id} blok={entry} />
            ))}
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default Blog
