import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import cx from 'classnames'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import TextRenderer from '../TextRenderer'

import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'

const Item = React.forwardRef(({ blok, className }, ref) => {

    const imageClasses = cx({
        [styles['spotlight-item__image']]: true,
        [styles['spotlight-item__image--shadow']]: blok.add_image_shadow
    })

    return (
        <SbEditable content={blok} key={blok._uid}>
            <li ref={ref} className={className}>
                <article className={styles['spotlight-item__inner']}>
                    <header>
                        <TextRenderer text={blok.heading} />
                    </header>
                    <figure className={`${styles['spotlight-item__image-wrapper']} ${blok.image_size ? styles[blok.image_size] : ''}`}>
                        {blok.image.filename && <ResponsiveImage className={imageClasses} image={blok.image} mobile={200} desktop={400} large={600} />}
                    </figure>
                    <footer className={styles['spotlight-item__footer']}>
                        <TextRenderer className={`link link--large ${styles['spotlight-item__body']}`} text={blok.body} />
                        <TextRenderer className={`link--large`} text={blok.link} />
                    </footer>
                </article>
            </li>
        </SbEditable>
    )
})

const Spotlight = ({ blok }) => {

    const sectionRef = useRef(null)
    const itemRefs = useRef([])

    // useEffect(() => {
        
    //     if (typeof window !== 'undefined') {
    //         gsap.registerPlugin(ScrollTrigger)
    //     }
        
    //     const tl = gsap.timeline({
    //         immediateRender: false,
    //         scrollTrigger: {
    //             trigger: sectionRef.current,
    //             // scrub: true,
    //             start: 'top top',
    //             end: 'top+=50%'
    //         }
    //     })

    //     itemRefs.current.forEach((item, i) => {
    //         tl.fromTo(item, {
    //             x: i == 0.0 ? -150.0 : 150.0,
    //             alpha: 0.0
    //         }, {
    //             x: 0.0,
    //             alpha: 1.0
    //         }, 0.0)
    //     })

    //     return () => {
    //         if (tl) tl.kill()
    //     }

    // }, [])

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section ref={sectionRef} className={styles['spotlight']}>
                <ul className={styles['spotlight__list']}>
                    {blok.items && blok.items.map((entry, i) => 
                        <Item ref={el => itemRefs.current[i] = el} className={styles['spotlight-item']} key={entry._uid} blok={entry} />
                    )}
                </ul>
            </section>
        </SbEditable>
    )
}

export default Spotlight
