import React, { useRef, useState, useEffect } from "react"
import SbEditable from "storyblok-react"
import cx from "classnames"
import gsap from "gsap"

import TextRenderer from "./../TextRenderer"
import ButtonPrimary from "./../ButtonPrimary"
import ResponsiveImage from "./../ResponsiveImage"

import styles from "./styles.module.scss"

const Item = ({ blok }) => {
  const accordionRefs = useRef([])
  const [activeIndex, setActiveIndex] = useState(-1)

  const toggleAccordion = index => {
    accordionRefs.current.forEach(item => {
      gsap.to(item, { height: 0, ease: "power2.easeOut", duration: 0.25 })
    })

    if (index == activeIndex) {
      setActiveIndex(-1)
      gsap.to(accordionRefs.current[index], {
        height: 0.0,
        ease: "power2.easeOut",
        duration: 0.25,
      })
    } else {
      setActiveIndex(index)
      gsap.to(accordionRefs.current[index], {
        height: "auto",
        ease: "power2.easeOut",
        duration: 0.25,
      })
    }
  }

  useEffect(() => {
    accordionRefs.current.forEach(item => {
      gsap.set(item, { height: 0 })
    })
  }, [])

  return (
    <SbEditable content={blok}>
      <article className={styles["career-item"]}>
        {blok.image && blok.image.filename && (
          <div className={styles["career-item__image-wrapper"]}>
            <ResponsiveImage
              className={styles["career-item__image"]}
              image={blok.image}
            />
          </div>
        )}
        <div className={styles["career-item__inner"]}>
          <div
            className={`${styles["career-item__block"]} ${styles["career-item__header"]}`}
          >
            <h5 className="heading-4">
              <span className="subtitle">Vacature</span>
            </h5>
            <h4 className="heading-3">
              <b>{blok.title}</b>
            </h4>
          </div>
          <div
            className={`${styles["career-item__block"]} ${styles["career-item__information"]}`}
          >
            <ul>
              {blok.bullets &&
                blok.bullets.map((entry, i) => (
                  <li key={i}>
                    <span
                      className={styles["career-item__bullet-icon-wrapper"]}
                    >
                      <i
                        className={`${entry.icon.type} ${entry.icon.icon} ${styles["career-item__bullet-icon"]}`}
                      ></i>
                    </span>
                    <span className="paragraph">{entry.label}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div
            className={`${styles["career-item__block"]} ${styles["career-item__description"]}`}
          >
            <TextRenderer text={blok.description} />
          </div>
          <div className={styles["career-item__accordion"]}>
            <div>
              {blok.information &&
                blok.information.map((entry, i) => {
                  const itemClasses = cx({
                    [styles["career-accordion-item"]]: true,
                    [styles["career-accordion-item--active"]]: i == activeIndex,
                  })

                  return (
                    <div className={itemClasses} key={i}>
                      <div
                        className={styles["career-accordion-item__title"]}
                        onClick={() => toggleAccordion(i)}
                      >
                        <h6 className="paragraph">
                          <b>{entry.title}</b>
                        </h6>
                        <span
                          className={styles["career-accordion-item__button"]}
                          onClick={() => toggleAccordion(i)}
                        >
                          <i
                            className={`fas fa-angle-down ${styles["career-accordion-item__button-icon"]}`}
                          ></i>
                        </span>
                      </div>
                      <div
                        className={styles["career-accordion-item__body"]}
                        ref={el => (accordionRefs.current[i] = el)}
                      >
                        <TextRenderer
                          text={entry.content}
                          className={styles["career-accordion-item__text"]}
                          linkAsText
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={styles["career-item__button-wrapper"]}>
            {blok.button &&
              blok.button.map(entry => (
                <ButtonPrimary key={entry._uid} {...entry} />
              ))}
          </div>
        </div>
      </article>
    </SbEditable>
  )
}

const Careers = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className={styles["careers"]} id="careers" name="careers">
        <div className="container container--small">
          {blok.items &&
            blok.items.map(entry => <Item key={entry._uid} blok={entry} />)}
        </div>
      </section>
    </SbEditable>
  )
}

export default Careers
