import React from 'react'
import SwiperCore, { Scrollbar, Autoplay, EffectFade , Navigation, Pagination } from 'swiper'
import { Swiper } from 'swiper/react'

import 'swiper/swiper.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

SwiperCore.use([Scrollbar, Autoplay, EffectFade, Navigation, Pagination])

const DynamicCarousel = ({ 
    className, 
    children, 
    params, 
    onTouchStart = null, 
    onTouchEnd = null 
}) => {

    return (
        <div className={`carousel ${className}`}>
            <Swiper
                {...params}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >{children}</Swiper>
        </div>
    )
}

export default DynamicCarousel
