import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import TextRenderer from '../TextRenderer'

import styles from './styles.module.scss'

const TextBlock = ({ blok }) => {

    const wrapperRef = useRef(null)
    const innerRef = useRef(null)
    
    useEffect(() => {
        
        if (typeof window !== 'undefined') {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.set(innerRef.current, { alpha: 0.0, y: 200, scale: 0.5})
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: wrapperRef.current,
                scrub: true,
                start: '-100%',
                end: '25%'
            }
        })

        tl.to(innerRef.current, {
            y: 0.0,
            alpha: 1.0,
            scale: 1.0
        }, 0.0)

        return () => {
            if (tl) tl.kill()
        }

    }, [])

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section ref={wrapperRef} className={styles['text-block']} style={{ backgroundColor: blok.background_color }}>
                <div ref={innerRef} className={styles['text-block__inner']} style={{ backgroundColor: blok.block_color }}>
                    <TextRenderer text={blok.body} />
                </div>
            </section>
        </SbEditable>
    )
}

export default TextBlock
