import React, { useState, useEffect } from "react"
import SbEditable from "storyblok-react"

import ReactPlayer from "react-player"

import ButtonPlaySvg from "./../../images/button-play.svg"
import ButtonFullscreenSvg from "./../../images/button-fullscreen.svg"
import ButtonPauseSvg from "./../../images/button-pause.svg"

import styles from "./styles.module.scss"

const Video = ({ blok }) => {
  const [videoReady, setVideoReady] = useState(false)
  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState(0)
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    var sec_num = duration - progress.playedSeconds
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor((sec_num - hours * 3600) / 60)
    var seconds = Math.round(sec_num - hours * 3600 - minutes * 60)

    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    setTimeLeft(minutes + ":" + seconds)
  }, [progress])

  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className={styles["video"]}>
        <div className={styles["video__wrapper"]}>
          <div className={styles["video__component"]}>
            <ReactPlayer
              url={blok.url.url}
              width="100%"
              height="100%"
              playing={videoReady}
              allowFullScreen="allowFullScreen"
              frameBorder="0"
              controls={false}
              className={styles["video__react-player"]}
              playsinline={true}
              loop={true}
              onDuration={e => setDuration(e)}
              onProgress={e => setProgress(e)}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    fs: 0,
                    disablekb: 1,
                  },
                },
              }}
            />
            {!videoReady && (
              <div
                className={styles["video-preview"]}
                onClick={() => setVideoReady(true)}
              >
                <img
                  className={styles["video-preview__background"]}
                  src={blok.placeholder_image.filename}
                  alt="Living Creations Video"
                />
                <button
                  className={`button ${
                    blok.center_label
                      ? styles["video-preview__play"]
                      : styles["video-preview__play--is-off-center"]
                  }`}
                >
                  <ButtonPlaySvg
                    className={styles["video-preview__button-play"]}
                  />
                  <span className={styles["video-preview__text"]}>
                    {blok.play_label || "Play Video"}
                  </span>
                </button>
              </div>
            )}
            {videoReady && (
              <div className={styles["video-playing"]}>
                <a
                  className={styles["video-fullscreen"]}
                  href={blok.url.url}
                  target="_blank"
                >
                  <ButtonFullscreenSvg
                    className={styles["video-fullscreen__button-fullscreen"]}
                  />
                </a>
                <div className={styles["video-fullscreen__controls"]}>
                  <button
                    className={`button ${styles["video-fullscreen__pause"]}`}
                    onClick={() => setVideoReady(false)}
                  >
                    <ButtonPauseSvg
                      className={styles["video-fullscreen__button-pause"]}
                    />
                  </button>
                  <div className={styles["video-fullscreen__progress"]}>
                    {timeLeft}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default Video
