import React from 'react'
import DynamicPage from './DynamicPage'
import DynamicPost from './DynamicPost'
import Placeholder from './Placeholder'
import HeroBanner from './HeroBanner'
import Row from './Row'
import VerticalSlides from './VerticalSlides'
import Spacer from './Spacer'
import Banner from './Banner'
import Marquee from './Marquee'
import Evolution from './Evolution'
import Spotlight from './Spotlight'
import Footer from './Footer'
import TextBlock from './TextBlock'
import Collective from './Collective'
import Information from './Information'
import Grid from './Grid'
import Carousel from './Carousel'
import Downloads from './Downloads'
import Contact from './Contact'
import WhereToBuy from './WhereToBuy'
import VerticalBlocks from './VerticalBlocks'
import Gallery from './Gallery'
import Team from './Team'
import ParallaxHeader from './ParallaxHeader'
import Careers from './Careers'
import Blog from './Blog'
import Image from './Image'
import AnchorSection from './AnchorSection'
import Video from './Video'

const Components = {
	'page': DynamicPage,
	'blogpost': DynamicPost,
	'hero_banner': HeroBanner,
	'row': Row,
	'vertical_slides': VerticalSlides,
	'spacer': Spacer,
	'banner': Banner,
	'marquee': Marquee,
	'evolution': Evolution,
	'spotlight': Spotlight,
	'footer': Footer,
	'text_block': TextBlock,
	'collective': Collective,
	'information': Information,
	'grid': Grid,
	'carousel': Carousel,
	'downloads': Downloads,
	'contact': Contact,
	'where_to_buy': WhereToBuy,
	'vertical_blocks': VerticalBlocks,
	'gallery': Gallery,
	'team': Team,
	'parallax_header': ParallaxHeader,
	'careers': Careers,
	'blog': Blog,
    'image': Image,
    'anchor_section': AnchorSection,
    'video': Video
}

const DynamicComponent = ({ blok, settings }) => {
	
	if (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component]
		return <Component blok={blok} key={blok._uid} settings={settings} />
	}
	
	return  blok.component ? <Placeholder componentName={blok.component}/> : null
	
}

export default DynamicComponent
