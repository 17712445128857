import React, { useEffect, useRef } from 'react'
import SbEditable from 'storyblok-react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import cx from 'classnames'

import ImageTitle from './../ImageTitle'
import ResponsiveImage from './../ResponsiveImage'

import styles from './styles.module.scss'

const ParallaxHeader = ({ blok }) => {

    const wrapperRef = useRef(null)
    const imageRefs = useRef([])
    
    useEffect(() => {
        
        if (typeof window !== 'undefined') {
            gsap.registerPlugin(ScrollTrigger)

            if (window.innerWidth < 768) return
        }

        gsap.set(imageRefs.current[0], { yPercent: -5 })
        gsap.set(imageRefs.current[1], { yPercent: -10 })
        gsap.set(imageRefs.current[2], { yPercent: 15 })
        
        let tl = gsap.timeline({
            immediateRender: false,
            scrollTrigger: {
                trigger: wrapperRef.current,
                scrub: true, 
                start: 'center bottom',
                end: 'center top +=100%'
            }
        })

        tl.to(imageRefs.current[0], {
            yPercent: 0
        }, 0.0)
        imageRefs.current[1] && tl.to(imageRefs.current[1], {
            yPercent: 5
        }, 0.0)
        imageRefs.current[2] && tl.to(imageRefs.current[2], {
            yPercent: 0
        }, 0.0)

        return () => {
            if (tl) tl.kill()
        }
    }, [])

    const resizeHandler = () => {

        let vh = Math.max(wrapperRef.current.clientHeight, window.innerHeight || 0);
        wrapperRef.current.style.setProperty('--vh', `${vh}px`)
        
    }

    useEffect(() => {
        
        resizeHandler()

        window.addEventListener('resize', resizeHandler)
        
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const bannerClasses = cx({
        [styles['parallax-header']]: true,
        [styles['parallax-header--in-container']]: blok.type == 'in-container',
        [styles['parallax-header--no-spacing']]: blok.no_spacing
    })

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section ref={wrapperRef} className={bannerClasses}>
                <div className={`img-hover ${styles['parallax-header__inner']}`}>
                    {blok.layers && blok.layers.map((entry, i) => 
                        <div className={styles['parallax-header__image-wrapper']} key={i}>
                            <ResponsiveImage ref={el => imageRefs.current[i] = el} className={styles['parallax-header__image']} image={entry} desktop={2000} />
                        </div>
                    )}
                    <div className={`${styles['parallax-header__image-wrapper']} ${styles['parallax-header__image-wrapper--is-mobile']}`}>
                        <ResponsiveImage className={styles['parallax-header__image']} image={blok.fallback} mobile={400} desktop={600} />
                    </div>
                    <ImageTitle className={styles['parallax-header__image-title']} direction='horizontal' text={blok.title} />
                </div>
            </section>
        </SbEditable>
    )
}

export default ParallaxHeader
