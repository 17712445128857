import React from 'react'
import cx from 'classnames'
import SbEditable from 'storyblok-react'

import TextRenderer from './../TextRenderer'

import styles from './styles.module.scss'

const Row = ({ blok }) => {

    const wrapperClasses = cx({
        [styles['row']]: true,
        [styles['row--no-spacing']]: blok.no_spacing,
        [styles['row--hide-on-mobile']]: blok.hide_on_mobile,
        [styles['row--hide-on-desktop']]: blok.hide_on_desktop
    })
    
    const containerClasses = cx({
        'container': true,
        'container--small': blok.max_width == 'small'
    })

    const rowClasses = cx({
        'row': true,
        'row--center': blok.columns.length == 1 && blok.columns[0].text_align == 'center'
    })

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={wrapperClasses} data-type='row' style={{ 'backgroundColor': blok.background_color }}>
                <div className={containerClasses}>
                    <div className={rowClasses}>
                        {blok.columns.map((entry, i) => {
                            
                            const colClasses = cx({
                                'col col-12': true,
                                'mb-5': (i + 1) != blok.columns.length,
                                ['text-center']: entry.text_align == 'center',
                                [`col-md-${entry.size}`]: entry.size
                            })

                            return (
                                <SbEditable content={entry} key={entry._uid}>
                                    <div className={colClasses}>
                                        <TextRenderer text={entry.body} linkAsText />
                                    </div>
                                </SbEditable>
                            )
                        })}
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default Row
