import React, { useState, useEffect, useRef } from 'react'
import stickybits from 'stickybits'

import styles from './styles.module.scss'

const calcDynamicHeight = objectWidth => {
    const vw = window.innerWidth
    const vh = window.innerHeight
    return objectWidth - vw + vh
}

const handleDynamicHeight = (ref, setDynamicHeight) => {
    const objectWidth = ref.current.scrollWidth
    const dynamicHeight = calcDynamicHeight(objectWidth)
    setDynamicHeight(dynamicHeight)
}

const HorizontalScroll = ({ children }) => {

    const [dynamicHeight, setDynamicHeight] = useState(null)
    const [translateX, setTranslateX] = useState(0)

    const containerRef = useRef(null)
    const objectRef = useRef(null)

    const resizeHandler = () => {
        handleDynamicHeight(objectRef, setDynamicHeight)
    }

    const scrollHandler = () => {
        const offsetTop = -containerRef.current.offsetTop
        setTranslateX(offsetTop)
    }

    useEffect(() => {
        
        handleDynamicHeight(objectRef, setDynamicHeight)
        stickybits(containerRef.current)

        window.addEventListener('resize', resizeHandler)
        window.addEventListener('scroll', scrollHandler)
        
        return () => {
            window.removeEventListener('resize', resizeHandler)
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    return (
        <div className={styles['horizontal-scroll__outer-container']} style={{ height: `${dynamicHeight}px` }}>
            <div ref={containerRef} className={styles['horizontal-scroll__sticky-inner']}>
                <div ref={objectRef} className={styles['horizontal-scroll__translate-container']} style={{ transform: `translateX(${translateX}px)` }}>
                    {children}
                </div>
                {/* <div className={styles['horizontal-scroll__indicator']}>
                    <div className={styles['horizontal-scroll__indicator-inner']} style={{ width: `${translateX / 100}%)` }}></div>
                </div> */}
            </div>
        </div>
    )
}

export default HorizontalScroll