import React from 'react'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql } from 'gatsby'

import PostArticle from './PostArticle'
import Footer from './Footer'

const DynamicPost = ({ blok, title, slug, date, settings }) => {

	const footerBlok = blok.footer && blok.footer.map(childBlok => <Footer blok={childBlok} key={childBlok._uid} settings={settings} />)
	
	return (
		<SbEditable content={blok} key={blok._uid}>
			<PostArticle title={title} slug={slug} date={date} blok={blok} settings={settings} />
			{footerBlok}
		</SbEditable>
	)
}

export default DynamicPost
